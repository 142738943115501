<template>
  <div>
    <div class="bg404">
        <div class="left">
          <img v-if="language_type == 1" src="../assets/404CN.png"/><img v-if="language_type == 2" src="../assets/404EN.png"/>
          <div class="back" @click="tohome">返回首页</div>
        </div>
        <div class="right">
          <img src="../assets/404.png"/>
        </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  components: {
    Myfoot
  },
  data(){
    return{
      language_type:""
    }
  },
  mounted() {
    this.language_type = localStorage.getItem("Language_type")
  },
  methods:{
    tohome(){
      this.$router.replace("/")
    },
  }

}
</script>
<style lang="scss" scoped>

.bg404 {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    margin-right: 200px;
    img {
      width: 300px;
    }
    .back{
      background-color: #27BA69;
      color: #FFFFFF;
      width: 100px;
      text-align: center;
      line-height: 35px;
      margin: auto;
      cursor: pointer;
    }
  }

  .right {
    img {
      width: 300px;
    }
  }
}

@media (min-width: 0px) and (max-width: 800px) {
  .bg404{
    display: flex;
    flex-direction: column-reverse;
    .left{
      margin-right: 0;
    }
  }

}
</style>
